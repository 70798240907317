#services-section {
    p {
        margin-bottom: 0;
    }

    .bg-coral-red {
        color: white;
    }
}

#auditorias-cierre-section {
    .row {
        margin-top: 0.5rem;
        padding: 1rem 0.5rem;
        font-size: 0.875rem;
    }

    .row:nth-child(odd) {
        background-color: rgba(var(--bs-coral-red-rgb));
        color: white;
    }

    .row:nth-child(even) {
        border: 3px solid #9f9e9e;
        background-color: #eeeeee;
    }
}