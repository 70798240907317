#our-company-section-1 {
    background: url("/assets/img/quienes-somos-sercol.webp") 50% 50% / cover no-repeat;
}

#our-company-section-2 {
    background-position: center center;
    background-size: 50% auto;
    background-repeat: no-repeat;
}

#our-company-section-3 {
    background-color: #e4e4e4;

    .container {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.nosotros-valores {
    .col-12 {
        text-align: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    h3 {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }

    img {
        max-height: 150px;
        margin-bottom: 1.5rem;
    }

    @media (min-width: 992px) { /* Ajusta según el breakpoint sm de tu versión específica de Bootstrap */
        .line-between-cols .col-12:not(:last-child)::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          top: 10%;
          bottom: 10%;
          width: 1px;
          background-color: #fff;
        }
      
        .line-between-cols .col-12 {
          position: relative;
        }
      }
    
    ul {
        text-align: left;
        margin-left: 3rem;
    }
}