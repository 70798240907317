#welcome-section {
    height: 40vh;
    background-position: center center;
    background-size: 50% auto;
    background-repeat: no-repeat;
    background: url("/assets/img/trabajo-equipo-sercol-certifica.webp") 50% 50% / cover no-repeat;
}

#home-slide-2 {
    background-position: center center;
    background-size: 50% auto;
    background-repeat: no-repeat;
}

#home-slide-3 {
    background: url("/assets/img/home-slide-3-bg.webp") 50% 50% / cover no-repeat;
}

#formulario-de-contacto {
    background: url("/assets/img/home-slide-5-bg.webp") 50% 50% / cover no-repeat;
}

#contactform {
    background-color: rgba(255, 255, 255, 0.9);
}

.testimony-headshot {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

#testimonies-slider {
    
    .quote-svg {
        position: absolute;
        transform: translateY(-50%);
        height: 50px;
        width: auto;
    }

    .quote-left {
        top: 50px;
        left: 10%;
    }

    .quote-right {
        bottom: 0;
        right: 10%;
    }
}